import React, { useEffect } from "react";
import { Link } from "gatsby";
import { PageLoader } from "../components/pageLoader";

// Hooks, Querys & State
import { useAppState } from "../state/appState";

// Components
import { PageMeta } from "../components/pageMeta";

const NotFoundPage = () => {
    const { setNavDark } = useAppState();

    useEffect(() => {
        setNavDark(true);
    }, [setNavDark]);

    return (
        <>
            <PageMeta
                metaTitle="404: Not Found"
                metaDescription="We can't seem to find the page you are looking for."
            />
            <div className="bg-warm-white">
                <div className="px-gutter lg:px-12 py-40 md:py-64 xl:py-80 flex flex-col items-center text-center gap-8 max-w-5xl mx-auto">
                    <h1 className="blockH10 text-teal">404</h1>
                    <h2 className="blockH1 text-teal">
                        We can't seem to find the page you are looking for.
                    </h2>
                    <Link
                        to="/"
                        className="button px-8 py-5 bg-teal text-warm-white hover:bg-raspberry"
                    >
                        Go back to home
                    </Link>
                </div>
            </div>
            <PageLoader />
        </>
    );
};

export default NotFoundPage;
